<template>
  <v-container>
    <v-card class="mt-2">
      <v-card-title> Geräte in Reparatur </v-card-title>
      <v-data-table
        :headers="headers"
        :items="services"
        :items-per-page="15"
        :search="search"
        :loading="loading"
        sort-by="startDate"
        sort-desc
      >
        <template v-slot:item.device.name="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                outlined
                :to="{
                  name: 'InventoryDevice',
                  params: { id: item.device.id },
                }"
                >{{ item.device.name }}
              </v-chip>
            </template>
            <span>Dieses Gerät bearbeiten</span>
          </v-tooltip>
        </template>
        <template v-slot:item.company.companyName="{ item }">
          <v-edit-dialog
            cancel-text="Abbrechen"
            save-text="Speichern"
            large
            :return-value.sync="item.company"
            @save="updateActivity(item)"
          >
            <v-chip class="clickable" v-if="item.company && item.company.id">
              {{ item.company.companyName }}, {{ item.company.town }}</v-chip
            ><v-chip class="clickable" v-else>+</v-chip>
            <template v-slot:input>
              <InventoryCompanyPicker v-model="item.company"
            /></template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.startDate="{ item }">
          <DatePicker
            color="info"
            @input="updateActivity(item)"
            v-model="item.startDate"
          />
        </template>
        <template v-slot:item.dueDate="{ item }">
          <DatePicker
            color="error"
            @input="updateActivity(item)"
            v-model="item.dueDate"
          />
        </template>
        <template v-slot:item.endDate="{ item }">
          <DatePicker
            color="success"
            @input="updateActivity(item)"
            v-model="item.endDate"
          />
        </template>
        <template v-slot:item.comment="{ item }">
          <v-edit-dialog
            cancel-text="Abbrechen"
            save-text="Speichern"
            large
            :return-value.sync="item.comment"
            @save="updateActivity(item)"
          >
            <v-tooltip top v-if="item.comment">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-comment-text</v-icon>
              </template>
              <span>{{ item.comment }}</span>
            </v-tooltip>
            <v-icon v-else>mdi-comment-plus</v-icon>
            <template v-slot:input>
              <v-textarea v-model="item.comment"></v-textarea>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="red"
                @click="trash(item.id)"
                ><v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </template>
            <span>Diesen Ausleiheintrag löschen</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import InventoryCompanyPicker from '@/components/inventory/CompanyPicker';
export default {
  name: 'InventoryServices',
  props: ['search'],
  components: { DatePicker, InventoryCompanyPicker },
  data() {
    return {
      services: [],
      loading: false,
      headers: [
        { text: 'Typ', value: 'device.deviceType.description' },
        { text: 'Standort', value: 'device.location.code' },
        { text: 'Modell', value: 'device.brandModel.description' },
        { text: 'Name', value: 'device.name' },
        { text: 'Firma', value: 'company.companyName' },
        { text: 'begin', value: 'startDate' },
        { text: 'end', value: 'endDate' },
        { text: '', value: 'comment' },
        { text: '', value: 'actions', sortable: false },
      ],
    };
  },
  async mounted() {
    await this.loadActivities();
  },
  methods: {
    async loadActivities() {
      this.loading = true;
      this.services = await this.apiList({
        resource: 'inventory/activity',
        query: 'serviced',
      });
      this.loading = false;
    },
    async updateActivity(item) {
      await this.apiPut({ resource: 'inventory/activity', data: item });
      this.$root.showSuccess('erfolgreich gespeichert');
    },

    async trash(id) {
      if (
        await this.$root.confirm({
          message: `Soll diese Ausleihe gelöscht werden?`,
          color: 'red',
          icon: 'mdi-trash-can',
        })
      ) {
        await this.apiDelete({
          resource: 'inventory/activity',
          id: id,
        });
        this.loadActivities();
      }
    },
    formatDate(value) {
      const date = new Date(value);
      if (isNaN(date)) {
        return '+';
      }
      return date.toLocaleDateString('de-CH', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    },
  },
};
</script>
